<template>
  <div class="headline">
    <div class="combination">
      <div class="circle"></div>
      <div class="line"></div>
    </div>
    <div class="title">
      <h3>{{ title }}</h3>
      <p>{{ subtitle }}</p>
    </div>
    <div class="combination">
      <div class="line"></div>
      <div class="circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Headline",
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
  }
};
</script>

<style lang="scss" scoped>
.headline {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  .combination{
    height: 40px;
    display: flex;
    align-items: center;
    .circle{
      width: 8px;
      height: 8px;
      background: #B2B8C0;
      border-radius: 50%;
    }
    .line{
      width: 30px;
      height: 2px;
      background: #B2B8C0;
    }
  }
  .title {
    text-align: center;
    font-weight: bold;
    font-family: Source Han Sans CN;
    padding: 0 15px;
    h3{
      font-size: 30px;
      color: #333333;
    }
    p{
      font-size: 24px;
      color: #666565;
    }
  }

}
</style>